@use 'sass:map';
@use "sass:color";
@use '@angular/material' as mat;
@mixin dottnet-toolbar-component-theme($theme) {
    $color-config: map.get($theme, color);
    $primary-palette: map.get($color-config, primary);
    $accent-palette: map.get($color-config, accent);
    $warn-palette: map.get($color-config, warn);
    $primary-darker-color: map.get($primary-palette, 400);
    $primary-lighter-color: map.get($primary-palette, 100);
    $primary-lighter-color: color.mix(white, $primary-lighter-color, 50%); // 20% indica quanto bianco aggiungere
    $primary-contrast-color: map.get($primary-palette, '500-contrast');
    $accent-color: map.get($accent-palette, 500);
    $accent-contrast-color: map.get($accent-palette, '500-contrast');
    mat-toolbar {
        border-bottom: 1px solid $primary-darker-color;
        background-color: $primary-lighter-color;
        button {
            &.active {
                color: $accent-contrast-color;
                background-color: $accent-color;
            }
        }
        .link {
            color: $primary-contrast-color;
        }
        .menu-hr {
            border: 1.5px solid $primary-darker-color;
        }
        mat-toolbar {
            border-bottom: 1px solid $primary-darker-color;
        }
    }
}
