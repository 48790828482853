@use 'sass:map';
@use "sass:color";
@use '@angular/material' as mat;
@mixin dottnet-sidenav-component-theme($theme) {
    $color-config: map.get($theme, color);
    $primary-palette: map.get($color-config, primary);
    $accent-palette: map.get($color-config, accent);
    $warn-palette: map.get($color-config, warn);
    $primary-lighter-color: map.get($primary-palette, 100);
    $primary-lighter-color: color.mix(white, $primary-lighter-color, 50%); // 20% indica quanto bianco aggiungere
    $accent-color: map.get($accent-palette, 500);
    .branding {
        background-color: $primary-lighter-color;
    }
    .branding a {
        color: $accent-color;
    }
    .rootLink {
        color: $accent-color;
    }
}
