@use "@angular/material" as mat;
@use "sass:map";
@use '../shared/toolbar/toolbar.component.scss-theme' as *;
@use '../shared/sidenav/sidenav.component.scss-theme' as *;
@use '../shared/footer/footer.component.scss-theme' as *;
@mixin dottnet-app-component-theme($theme) {
    @include dottnet-footer-component-theme($theme);
    @include dottnet-sidenav-component-theme($theme);
    @include dottnet-toolbar-component-theme($theme);
    $primary: map.get($theme, primary);
    $accent: map.get($theme, accent);
    $warn: map.get($theme, warn);
    mat-sidenav {
        background-color: mat.m2-get-color-from-palette($primary, lighter);
        border-right: 1px solid rgba(21, 101, 192, 0.5);
    }
    .success-notification-overlay,
    .info-notification-overlay,
    .error-notification-overlay {
        color: rgba(0, 0, 0, 0.8);
        background-color: mat.m2-get-color-from-palette($primary, lighter);
        border: 3px solid mat.m2-get-color-from-palette($accent, darker);
        button {
            background-color: mat.m2-get-color-from-palette($accent, lighter);
        }
    }
}
