@use "@angular/material" as mat;
@use "sass:map";
@mixin dottnet-profile-theme($theme) {
    $primary: map.get($theme, primary);
    $accent: map.get($theme, accent);
    $warn: map.get($theme, warn);
    $foreground: map.get($theme, foreground);
    $background: map.get($theme, background);
    .form-group--title {
        color: mat.m2-get-color-from-palette($accent, lighter);
    }
}
