@use 'sass:map';
@use '@angular/material' as mat;
@mixin dottnet-footer-component-theme($theme) {
    $color-config: map.get($theme, color);
    $primary-palette: map.get($color-config, primary);
    $accent-palette: map.get($color-config, accent);
    $warn-palette: map.get($color-config, warn);
    .footer {
        $primary-color: map.get($primary-palette, 500);
        $primary-lighter-color: map.get($primary-palette, 100);
        $accent-color: map.get($accent-palette, 500);
        color: map.get($primary-palette, '500-contrast');
        background-color: $primary-lighter-color;
        .links,
        .signature {
            a {
                color: $primary-lighter-color;
                &:hover {
                    color: $accent-color;
                }
            }
        }
    }
}
